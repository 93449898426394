import {browserLocalPersistence, getAuth, setPersistence, signInWithCustomToken} from "@firebase/auth";

export default defineNuxtRouteMiddleware(async (to) => {
    if (!process.client) return
    const { token } = to.query
    if (token) {
        console.log("Token: ", token)
        const auth = getAuth()
        await setPersistence(auth, browserLocalPersistence)
        try {
            const credential = await signInWithCustomToken(auth, token)
            return navigateTo({path: to.path}, {replace: false})
        } catch (e) {
            console.log("Could not login")
            console.log("Error: ", e)
            return to.path
        }
        return to.path
    }

})